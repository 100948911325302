import useDataVideoParse from '../../../hooks/useDataVideoParse';
import { clone, shiftPushArray } from '~/helpers/javascript';
import { textsByCategory, textsByCategoryV1 } from './textsByCategory';

const BuildVideos = async ({
  artboardVideoData,
  woxoVideoData,
  textPosition,
  videoShape,
  fontSize,
  music,
  textColors,
  contentVideoData,
  version
}) => {
  let def = clone(artboardVideoData);
  const { processAfterImport } = useDataVideoParse({
    setTotalVideos: () => {},
    setData: () => {},
    setGlobalError: () => {},
    setState: () => {},
    setIsLoading: () => {},
    useToast: false
  });

  const currentWoxoVideoData = clone(woxoVideoData);

  const _woxoVideoData = [];
  const numberVideos = def[0]?.videoDefinition.length;

  let sceneIds = [];
  for (let i = 0; i < def.length; i++) {
    sceneIds.push(def[i]?.id);
  }

  for (let idx = 0; idx < numberVideos; idx++) {
    let bgGradScenes = contentVideoData[idx]?.gradient;
    let element = {
      TextPosition: textPosition,
      TextStyle: contentVideoData[idx]?.textStyle,
      TextColor: contentVideoData[idx]?.textColor,
      BackgroundColor: contentVideoData[idx]?.backgroundColor,
      Font: contentVideoData[idx]?.font,
      Music: contentVideoData[idx]?.music,
      FontSize: fontSize,
      TextStroke: '8',
      TextShadow: '5',
      'Text Animation': 'typewrite',
      'Video Shape': videoShape,
      UseMusic: music !== 'None' ? true : false,
      UseOriginalMusic: false,
      UseVoice: false,
      BgGradScenes: bgGradScenes,
      UseBG: true,
      // using to control scene ids
      sceneIds: sceneIds
    };

    bgGradScenes.scenes = [];
    for (let index = 0; index < def.length; index++) {
      element[`Media ${index + 1}`] =
        def[index].medias?.length > 0
          ? shiftPushArray(def[index].medias).preview
          : 'https://res.cloudinary.com/dakp804eh/image/upload/v1619204369/woxo-video/error.png';
      // --- ***** Work with versions ***** ---
      const _texts =
        Number(version) === 1
          ? textsByCategoryV1(def[index].videoDefinition[idx])
          : textsByCategory(def[index].videoDefinition[idx]);
      // --------------------------------------
      element[`Title ${index + 1}`] = _texts.title;
      element[`Text ${index + 1}`] = _texts.text;

      if (def[index].medias?.length === 0) bgGradScenes.scenes.push(index + 1);
    }

    if (bgGradScenes.scenes.length === 0) {
      delete element['BgGradScenes'];
      delete element['UseBG'];
    }

    if (textColors.activate) {
      element.TextColor = textColors.text.color;
      element.BackgroundColor = textColors.background.color;
    }

    // console.log('element --> ', element);

    if (!_woxoVideoData.find((s) => JSON.stringify(s) === JSON.stringify(element)))
      _woxoVideoData.push({ id: currentWoxoVideoData[idx]?.data?.id, ...element });
  }

  const { errors, warnings, ...data } = await processAfterImport(_woxoVideoData);
  // console.log('error after shuffle', errors, warnings);
  // console.log('shuffle', data);
  return Object.values(data);
};

module.exports = BuildVideos;
