import { v4 } from 'uuid';
import colorString from 'color-string';
import { textAnimDuration } from '@front10/canvas-video-player/client';
import { clone, isFunction } from './javascript';
import { upload } from '../services/assets.services';

export const sceneAdapterDuration = ({ layers, duration, width, height }) => {
  const element = layers.find((l) =>
    [
      'woxo-itext',
      'woxo-solid',
      'woxo-stepUp',
      'woxo-stepCenter',
      'woxo-crissCross',
      'woxo-rollOut',
      'woxo-underLine',
      'woxo-centerLine',
      'woxo-lineUp',
      'woxo-boundary',
      'woxo-simpleClean',
      'woxo-honor',
      'woxo-popOut',
      'woxo-tradeMark',
      'woxo-brushStroke',
      'woxo-dreamMeme',
      'woxo-basic'
    ].includes(l.type)
  );
  if (element) {
    let time = 0; // textAnimDuration(element, { width, height });
    if (time !== 0) return time + 2;
    const totalText = element.text.replace(/\n/gm, ' ');
    let wordsPerSecond = 1;
    const words = totalText.trim().split(/\s+/).length;

    // si hay muchas palabras el cerebro las lees mas rapido
    if (words > 10) wordsPerSecond = 3;
    else if (words > 6) wordsPerSecond = 2;

    time = words / wordsPerSecond;
    // si hay 1 palabra el cerebro necesita mas tiempo que cuando no hay ninguna
    if (time < 3 && words > 0) time = 4;
    // si hay solo una imagen segarantiza que este un tiempo minimo
    else if (time < 3) time = 3;

    return time;
  }
  return duration;
};

const escapeRegex = (string) => string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

export const storageSVG = (video) =>
  new Promise((resolve, reject) => {
    const _video = clone(video);
    const promises = [];
    const indexs = [];
    _video.clips.map((clip, clipIndex) => {
      clip.layers.map((layer, layerIndex) => {
        if (layer.type === 'svg' && layer.path.startsWith('data:image')) {
          promises.push(upload({ content: layer.path }));
          indexs.push({ clip: clipIndex, layer: layerIndex });
        }
      });
    });
    Promise.all(promises)
      .then((response) => {
        response.map(({ secure_url }, index) => {
          const { clip, layer } = indexs[index];
          _video.clips[clip].layers[layer].path = secure_url;
        });
        resolve(_video);
      })
      .catch((e) => {
        reject(e);
      });
  });

export const transform = (json, id) =>
  new Promise((resolve, reject) => {
    try {
      const fonts = [];
      const video = {
        id,
        width: json.width,
        height: json.height,
        clips: json.pages.map((page, indexClip) => {
          const clip = {
            background: page.background,
            index: indexClip,
            transition: {
              duration: 0.25
            },
            duration: 4,
            id: `${id}-c_${indexClip}`,
            layers: page.children
              .map(({ type, _woxo = {}, ...child }, indexLayer) => {
                let layer = null;
                const { height, width, x, y, start, stop, variable } = child;
                const animation = { name: child['animation_name'] };

                switch (type) {
                  case 'text': {
                    if (
                      child.fontFamily &&
                      !fonts.includes(
                        `https://fonts.googleapis.com/css?family=${child.fontFamily.replace(
                          / /g,
                          '+'
                        )}`
                      )
                    ) {
                      fonts.push(
                        `https://fonts.googleapis.com/css?family=${child.fontFamily.replace(
                          / /g,
                          '+'
                        )}`
                      );
                    }
                    const { text } = child;
                    const style = {
                      textAlign: child.align === 'justify' ? 'left' : child.align,
                      fill: child.fill,
                      fontFamily: child.fontFamily,
                      angle: child.rotation,
                      lineHeight: child.lineHeight ? child.lineHeight - 0.2 : 1,
                      letterSpacing: child.letterSpacing,
                      textDecoration: child.textDecoration,
                      fontSize: child.fontSize,
                      fontStyle: child.fontStyle,
                      fontWeight: child.fontWeight,
                      opacity: child.opacity,
                      shadowBlur: child.shadowBlur,
                      shadowColor: child.shadowColor,
                      shadowOffsetX: child.shadowOffsetX,
                      shadowOffsetY: child.shadowOffsetY,
                      stroke: child.stroke,
                      strokeWidth: child.strokeWidth
                    };
                    layer = {
                      start,
                      stop,
                      animation,
                      type: 'polotno-itext',
                      text: text.replace(/'/g, '´').replace(/\n/gm, '<br>'),
                      style,
                      height: height,
                      width: width,
                      variable,
                      x,
                      y,
                      id: `${id}-c_${indexClip}-l_${indexLayer}`
                    };
                    break;
                  }
                  case 'image':
                  case 'svg': {
                    let src = child.src;
                    if (child.colorsReplace && type === 'svg') {
                      let decode = window.atob(src.replace(`data:image/svg+xml;base64,`, ''));
                      const colors = isFunction(child.colorsReplace.toJSON)
                        ? child.colorsReplace.toJSON()
                        : child.colorsReplace;
                      Object.keys(colors).map((source) => {
                        const target = colors[source];
                        if (source && target) {
                          const _rgb = colorString.get.rgb(source);
                          const hsl = colorString.to.hsl(_rgb);
                          const hex = colorString.to.hex(_rgb);
                          const hwb = colorString.to.hwb(_rgb);
                          const rgb = colorString.to.rgb(_rgb);
                          if (decode.match(new RegExp(escapeRegex(source), 'gm')))
                            decode = decode.replaceAll(source, target);
                          else if (decode.match(new RegExp(escapeRegex(rgb), 'gm')))
                            decode = decode.replaceAll(rgb, target);
                          else if (decode.match(new RegExp(escapeRegex(hwb), 'gm')))
                            decode = decode.replaceAll(hwb, target);
                          else if (decode.match(new RegExp(escapeRegex(hex.toUpperCase()), 'gm')))
                            decode = decode.replaceAll(hex.toUpperCase(), target);
                          else if (decode.match(new RegExp(escapeRegex(hex.toLowerCase()), 'gm')))
                            decode = decode.replaceAll(hex.toLowerCase(), target);
                          else if (decode.match(new RegExp(escapeRegex(hsl), 'gm')))
                            decode = decode.replaceAll(hsl, target);
                          else decode = decode.replace('<svg', `<svg fill="${target}"`);
                        }
                      });
                      src = `data:image/svg+xml;base64,${window.btoa(decode)}`;
                    }
                    layer = {
                      start,
                      stop,
                      animation,
                      type: type === 'image' ? 'woxo-image' : type,
                      path: src,
                      height,
                      width,
                      variable,
                      x,
                      y,
                      id: `${id}-c_${indexClip}-l_${indexLayer}`,
                      style: {
                        angle: child.rotation,
                        opacity: child.opacity,
                        cropHeight: child.cropHeight,
                        cropWidth: child.cropWidth,
                        borderColor: child.borderColor,
                        borderSize: child.borderSize,
                        borderRadius: child.cornerRadius,
                        crop:
                          child.cropX || child.cropY || child.cropWidth || child.cropHeight
                            ? {
                                x: child.cropX,
                                y: child.cropY,
                                width: child.cropWidth,
                                height: child.cropHeight
                              }
                            : null,
                        filters: {
                          blurRadius: child.blurEnabled ? child.blurRadius : 0,
                          brightness: child.brightnessEnabled ? child.brightness : 0,
                          sepia: child.sepiaEnabled,
                          grayscale: child.grayscaleEnabled,
                          shadow: child.shadowEnabled
                            ? { color: child.shadowColor, blur: child.shadowBlur }
                            : null
                        }
                      }
                    };
                    break;
                  }
                  case 'video': {
                    layer = {
                      start,
                      stop,
                      animation,
                      type: 'woxo-video',
                      path: child.src,
                      height: height,
                      width: width,
                      variable,
                      x,
                      y,
                      id: `${id}-c_${indexClip}-l_${indexLayer}`,
                      style: {
                        angle: child.rotation,
                        opacity: child.opacity
                      }
                    };
                    break;
                  }
                  case 'gradient': {
                    layer = {
                      type: 'woxo-background',
                      colors: JSON.parse(child.colors),
                      gradientType: child.gType,
                      animation: true,
                      width: json.width,
                      height: json.height,
                      id: `${id}-c_${indexClip}-l_${indexLayer}`
                    };
                    break;
                  }
                }
                layer.polotnoId = child.id;
                layer.index = indexLayer;
                return layer;
              })
              .filter((layer) => !!layer)
          };
          return clip;
        })
      };
      video.font = fonts || [];
      resolve(video);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

export const processBeforeSave = (json, title) =>
  new Promise((resolve, reject) => {
    const _json = clone(json);
    resolve({
      ..._json,
      metadata: { title },
      customFont: _json.font.map((f) => ({ path: f })),
      clips: _json.clips.map((clip) => {
        const layers = clip.layers;
        if (clip.background)
          layers.splice(0, 0, {
            type: 'woxo-background',
            colors: [clip.background],
            width: _json.width
          });
        return { ...clip, layers };
      })
    });
  });
