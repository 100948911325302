import { Box, Typography } from '@mui/material';
import useStyles from './styles';
import clsx from 'clsx';
import Router from 'next/router';

const WidgetsMenu = ({ items }) => {
  const classes = useStyles();

  const handleWidgetMenuItemClick = (itemTo, category) => {
    let pathnameForLocation = itemTo;
    if (category) {
      pathnameForLocation = `${itemTo}?cat=${category.value}`;
    }
    Router.push(pathnameForLocation);
  };

  return (
    <>
      {items.map((item) => (
        <Box key={item.name}>
          <Box
            className={clsx(classes.templateContent, 'videoContent')}
            onClick={() => {
              handleWidgetMenuItemClick(item.to);
            }}
          >
            <Box className={classes.templateImgBox}>
              <img src={item.image} alt={`${item.name} widget thumbnail`} />
            </Box>
            <Typography className={classes.categoryName}>{item.name}</Typography>
          </Box>
          {item.categories.map((category) => (
            <Box
              key={`${item.name}-${category.name}`}
              className={classes.categoriesBox}
              onClick={() => {
                handleWidgetMenuItemClick(item.to, category);
              }}
            >
              <Typography className={classes.categoryName}>{category.name}</Typography>
            </Box>
          ))}
        </Box>
      ))}
    </>
  );
};

export default WidgetsMenu;
