import React, { useState, useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Typography,
  CircularProgress
} from '@material-ui/core';
import {
  Adjust,
  CheckCircleOutline,
  PlayCircleOutline,
  NavigateBeforeRounded,
  NavigateNextRounded
} from '@material-ui/icons';
import { useStylesTutorial } from './styles';

//Buttons to select next or previous video
const StepControl = ({ changeStep, currentStep, amountSteps }) => {
  const classes = useStylesTutorial();

  const handleNext = () => {
    if (currentStep < amountSteps - 1) changeStep(currentStep + 1);
  };

  const handlePrev = () => {
    if (currentStep > 0) changeStep(currentStep - 1);
  };

  return (
    <Box width="100%">
      <Button
        color="primary"
        variant="text"
        disabled={currentStep === 0}
        className={classes.stepControlButton}
        startIcon={<NavigateBeforeRounded />}
        onClick={handlePrev}
      >
        Prev
      </Button>
      <Button
        color="primary"
        variant="text"
        disabled={currentStep === amountSteps - 1}
        className={classes.stepControlButton}
        endIcon={<NavigateNextRounded />}
        onClick={handleNext}
      >
        Next
      </Button>
    </Box>
  );
};

//Icon next to each video title
const StepIcon = ({ active, completed, ready }) => {
  const classes = useStylesTutorial();
  if (active) {
    if (ready)
      return (
        <PlayCircleOutline
          className={`${classes.activeStepIcon} current-tuturial`}
          color="primary"
        />
      );
    return (
      <div className={`${classes.activeStepIcon} current-tuturial`}>
        <CircularProgress size={24} />
      </div>
    );
  }
  if (completed) return <CheckCircleOutline className={classes.stepIcon} htmlColor="lightGreen" />;
  return <Adjust className={classes.stepIcon} color="action" />;
};

//The line that connects videos' icons with each other
const StepConnector = () => {
  const classes = useStylesTutorial();
  return <Box className={classes.stepConnector}></Box>;
};

//Each video title, icon and connector
const IndividualStep = ({ label, active, onClick, lastOne, videoReady, index, completed }) => {
  const classes = useStylesTutorial({ isFirstVideo: index === 0, isLastVideo: lastOne });

  return (
    <>
      <Step className={classes.stepComp} onClick={onClick}>
        <StepLabel icon={<StepIcon active={active} ready={videoReady} completed={completed} />}>
          <Typography variant="body2" className={active && classes.stepLabelSelected}>
            {label}
          </Typography>
        </StepLabel>
      </Step>
      {!lastOne && <StepConnector />}
    </>
  );
};

export default function TutorialStepper({ stepList, changeCurrentStep, currentStep, videoReady }) {
  const classes = useStylesTutorial();

  return (
    <Box className={classes.stepBoxRoot}>
      <Stepper classes={{ root: classes.stepRoot }} activeStep={currentStep} orientation="vertical">
        {stepList.map(({ label, completed }, index, arr) => (
          <IndividualStep
            key={label}
            index={index}
            active={index === currentStep}
            label={label}
            onClick={() => changeCurrentStep(index)}
            lastOne={index === arr.length - 1}
            videoReady={index === currentStep && videoReady}
            completed={completed}
          />
        ))}
      </Stepper>
      <StepControl
        currentStep={currentStep}
        changeStep={changeCurrentStep}
        amountSteps={stepList.length}
      />
    </Box>
  );
}
