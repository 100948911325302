import useTemplateReplacer from '@front10/woxo-video-helper/hooks/useTemplateReplacer';
import { downloadAndZip } from '~/helpers/downloadAndZip';
import { chunks, isArray, _async } from '~/helpers/javascript';
import { check, generateWithoutStatus } from '~/services/video.service';

export const useDownloadStore = (set, get) => ({
  // state
  videosToDownload: [],
  generatedVideos: [],
  generateProgress: 0,
  downloadPercent: 0,
  failedDownload: [],
  downloadType: 'video', //video | image | gif
  downloadStatus: 'default', //default | uploading | generating | downloading | finished | error-generating | error-downloading | full-plans
  componentStates: 'options', // options | progressInformation | progress | finished
  downloadRef: null,
  mediaPreview: {},

  // actions
  setVideosToDownloadAction: (videosToDownload) => set({ videosToDownload }),

  setGeneratedVideosAction: (generatedVideos) => set({ generatedVideos }),

  setGenerateProgressAction: (generateProgress) => {
    set({ generateProgress });
    get().setDownloadPercentAction((generateProgress / get().videosToDownload.length) * 100);
    get().setMediaPreviewAction(
      get().videosToDownload[generateProgress > 0 ? generateProgress - 1 : 0]?.data
    );
  },

  setDownloadPercentAction: (downloadPercent) => set({ downloadPercent }),

  setFailedDownloadAction: (failedDownload) => set({ failedDownload }),

  setDownloadTypeAction: (downloadType) => {
    get().trackingAction({
      event: 'onButtonClick',
      category: 'bulk-creator',
      action: `download-as-${downloadType}-click`,
      label: `${downloadType}`
    });
    set({ downloadType });
  },

  setDownloadStatusAction: (downloadStatus) => set({ downloadStatus }),

  setComponentStatesAction: (componentStates) => set({ componentStates }),

  setDownloadRefAction: (downloadRef) => set({ downloadRef }),

  setMediaPreviewAction: (mediaPreview) => set({ mediaPreview }),

  generateAction: async () => {
    if (!get().user) {
      // TODO: Make a better handler of flags
      localStorage.setItem('bulkCreatorState', JSON.stringify(get()));
      localStorage.setItem('downloadClick', true);
      get().showSignUpmodalAction(window.location.pathname, 'signup');
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const templateReplacer = useTemplateReplacer();
      get().setComponentStatesAction('progressInformation');

      let durationTime = {
        uploading: { start: null, end: null },
        exporting: { start: null, end: null },
        downloading: { start: null, end: null }
      };

      // TODO: Improve this part, create a subscribe
      if (get().uploading) {
        get().setMediaPreviewAction(get().woxoVideoData[0].data);
        get().setDownloadStatusAction('uploading');
        durationTime.uploading.start = new Date().getTime();
      }
      const checkIsUploading = (resolve) => {
        if (!get().uploading) {
          get().setVideosToDownloadAction(get().woxoVideoData);
          get().setDownloadStatusAction('generating');
          durationTime.uploading.end = new Date().getTime();
          get().setGenerateProgressAction(0);
          setTimeout(() => {
            get().setGenerateProgressAction(1);
          }, 1000);
          resolve();
        } else
          setTimeout(() => {
            if (get().downloadStatus === 'uploading') checkIsUploading(resolve);
          }, 1000);
      };
      await new Promise((resolve) => {
        checkIsUploading(resolve);
      });

      get().trackingAction({
        event: 'onButtonClick',
        category: 'bulk-creator',
        action: 'download-started'
      });

      durationTime.exporting.start = new Date().getTime();

      const availableGenerateVideos = get().videosToDownload.length;
      let json = false;

      const handleCheckGeneration = ({ jobid }) => {
        check({ jobid })
          .then((response) => {
            const r = async () => {
              if (response.totalFailed > 0) {
                get().setDownloadStatusAction('error-generating');
                get().setComponentStatesAction('progressInformation');
              }
              if (response.totalFailed + response.totalDone > 0)
                get().setGenerateProgressAction(response.totalFailed + response.totalDone);
              if (response.totalDone === availableGenerateVideos && response.data.length > 0) {
                const _videos = [];
                for (let i = 0; i < response.data.length; i++) {
                  const v = response.data[i];
                  const _title =
                    (v.editSpec && v.editSpec.metadata && v.editSpec.metadata.title) ||
                    v.editSpec.metadata.data['Title 1'] ||
                    v.editSpec.metadata.data.Headline ||
                    'woxo video';

                  _videos.push({
                    url: v.videoEndpoint,
                    landing: v.landing,
                    thumbnails: v.thumbnailsLink,
                    preview: v.previewLink,
                    outPath: v.outPath,
                    jobid: v.jobid,
                    taskid: v.taskid,
                    title: _title.replace(/<br>|~|\*/gi, ' '),
                    id: v._id,
                    metadata: v.editSpec.metadata,
                    fileName: v.editSpec.fileName,
                    index: v.editSpec.index,
                    downloadLink: v.downloadLink,
                    resolution: { width: v.editSpec.width, height: v.editSpec.height },
                    share: `${window.location.origin}/watch/${jobid}`
                  });
                }

                get().setGeneratedVideosAction(_videos);
                durationTime.exporting.end = new Date().getTime();
                get().downloadAction(durationTime);
              } else {
                if (get().downloadStatus === 'generating')
                  setTimeout(() => {
                    handleCheckGeneration({ jobid });
                  }, 4000);
              }
            };
            r();
          })
          .catch((error) => {
            console.log(error);
            get().setDownloadStatusAction('error-generating');
            get().setComponentStatesAction('progressInformation');
          });
      };

      try {
        json = templateReplacer(get().videosToDownload).map((spec) => {
          const metadata = Object.assign(spec.metadata || {}, {
            username: (get().user && get().user.username && `@${get().user.username}`) || '@public'
          });
          spec.metadata = metadata;
          spec.metadata.isSumoling = get().plan?.type === 'appsumo';
          const outFormat = get().downloadType === 'gif' ? 'gif' : 'mp4';
          return { ...spec, outFormat, outputVolume: 2 };
        });
      } catch (error) {
        console.log(error);
        get().setDownloadStatusAction('error-generating');
        get().setComponentStatesAction('progressInformation');
      }
      if (json)
        if (json.length > 0)
          generateWithoutStatus(json, get().projectCode, null, 100, true, 'bulk-creator')
            .then((response) => {
              handleCheckGeneration({
                jobid: response.data.jobid
              });
            })
            .catch((e) => {
              console.log(e);
              // TODO: - Plans
              get().setDownloadStatusAction('full-plans');
              get().setComponentStatesAction('progressInformation');
            });
        else {
          get().setDownloadStatusAction('error-generating');
          get().setComponentStatesAction('progressInformation');
        }
    }
  },

  downloadAction: async (durationTime = {}) => {
    get().setDownloadStatusAction('downloading');
    durationTime.downloading.start = new Date().getTime();
    get().setGenerateProgressAction(0);
    setTimeout(() => {
      if (get().generateProgress === 0) get().setGenerateProgressAction(1);
    }, 1000);
    const controller = new AbortController();
    const signal = controller.signal;
    const chunkSize = 25;

    const files = chunks(
      get().generatedVideos.map((v, i) => {
        let vdownloadLink = v.downloadLink;
        if (get().downloadType === 'image') vdownloadLink = v.thumbnails;
        return {
          isFirst: i === 0,
          landing: v.landing,
          downloadLink: vdownloadLink,
          name: v.title
            .replace(/( (\*<br>|<br>\*|<br>|\*))|((\*<br>|<br>\*|<br>|\*) )/gm, ' ')
            .replace(/\*<br>|<br>\*|<br>|\*/gm, ''),
          index: (v.index || i) + 1
        };
      }),
      chunkSize
    );

    try {
      let failure = [];
      for (let index = 0; index < files.length; index++) {
        const fail = await downloadAndZip({
          signal,
          selectedType:
            get().downloadType === 'video'
              ? 'Video'
              : get().downloadType === 'image'
              ? 'Image'
              : 'Gif',
          videos: files[index],
          name: `My WOXO ${
            get().downloadType === 'video'
              ? 'Videos'
              : get().downloadType === 'image'
              ? 'Images'
              : 'Gifs'
          }-${new Date().toISOString().split('T')[0]}-Part ${index + 1} of ${files.length}.zip`,
          downloadPopoverRef: get().downloadRef
        });

        if (isArray(fail)) failure = failure.concat(fail);
      }
      if (failure.length) {
        get().setDownloadStatusAction('error-downloading');
        get().setFailedDownloadAction(failure);
        get().setComponentStatesAction('progressInformation');
      } else {
        get().setDownloadStatusAction('finished');
        get().setGenerateProgressAction(get().generatedVideos.length);
        get().setComponentStatesAction('finished');
        durationTime.downloading.end = new Date().getTime();
        const _uploading = durationTime?.uploading?.start
          ? 'uploading_' +
            Math.round((durationTime?.uploading?.end - durationTime?.uploading?.start) / 1000) +
            's@'
          : '';
        const _exporting = durationTime?.exporting
          ? 'exporting_' +
            Math.round((durationTime?.exporting?.end - durationTime?.exporting?.start) / 1000) +
            's@'
          : '';
        const _downloading = durationTime?.downloading
          ? 'downloading_' +
            Math.round((durationTime?.downloading?.end - durationTime?.downloading?.start) / 1000) +
            's'
          : '';
        const _url = '@url_' + get().generatedVideos[0]?.share;
        const trackingLabel = _uploading + _exporting + _downloading + _url;
        get().trackingAction({
          event: 'onButtonClick',
          category: 'bulk-creator',
          action: 'download-finished',
          label: trackingLabel
        });
      }
    } catch (error) {
      console.log(error);
      get().setDownloadStatusAction('error-downloading');
      get().setComponentStatesAction('progressInformation');
    }
  },

  openDownloadMenuOptionsAction: () => {
    get().setComponentStatesAction('options');
    get().trackingAction({
      event: 'onButtonClick',
      category: 'bulk-creator',
      action: `download-button-click`,
      label: get().woxoVideoData.length
    });
  },

  resetDownloadMenuOptionsAction: () => {
    get().setComponentStatesAction('options');
    get().setDownloadStatusAction('default');
  },

  startDownloadAction: (videoType, downloadRef) => {
    get().setDownloadTypeAction(videoType);
    get().setDownloadRefAction(downloadRef);
    get().generateAction();
  },

  cancelDownloadAction: () => {
    get().trackingAction({
      event: 'onButtonClick',
      category: 'bulk-creator',
      action: `cancel-download-click`
    });
    get().resetDownloadMenuOptionsAction();
  }
});
