import QueryString from 'query-string';
import presets from '@front10/woxo-video-helper/data/template-generator/presets';
import resolutions from '@front10/woxo-video-helper/data/template-generator/resolutions';
import textaligns from '@front10/woxo-video-helper/data/template-generator/textaligns';
import {
  clone,
  isArray,
  isFunction,
  isImage,
  isUrl,
  isVideo,
  getDataBgGrad
} from '../helpers/javascript';
import { sceneAdapterDuration } from '../helpers/polotno';
import { preview } from '@front10/canvas-video-player/src/helpers/videos';
import { v4 } from 'uuid';

// const WOXO_PROXY_VIDEO_OPTIMIZER = 'https://api-dev.woxo.tech/vod/transform';

// TODO: This is not a React hook as the filename may imply. The file probably should be move/renamed to avoid confusion.
export const updateScenes = async (data, fn) => {
  try {
    const _data = clone(data);

    const videos = _data.map((s) => {
      // Retrieve pure template into data
      let replacer = JSON.stringify(clone(s.template));
      // Retrieve font family for current video
      const font = isUrl(s.data.Font) ? s.data.Font.split('/').pop().split('.')[0] : s.data.Font;
      s.data.FontSize = s.data.FontSize ? Number(s.data.FontSize) * 10 : 120;
      s.data.FontSize2 = (Number(s.data.FontSize) * 2) / 3;
      // Retrieve preset styles for current video
      const preset = presets.find((d) => d.name === s.data.TextStyle) || {};
      const textAlign = textaligns.find((ta) => ta.name === s.data.TextPosition) || {
        value: 'center'
      };
      const values = textAlign.value.split('-');
      if (preset && preset.style) {
        const styleFromPreset = JSON.stringify(preset.style);
        replacer = replacer.replace(
          /("|')___("|') *: *("|')@preset("|')/gm,
          styleFromPreset.substring(1, styleFromPreset.length - 1)
        );
        replacer = replacer.replace(/("|')@preset("|')/gm, styleFromPreset);
      }
      replacer = replacer.replace(/@font/g, font);
      let i = 0;
      const length = (replacer.match(/@alignment/g) || []).length;
      replacer = replacer.replace(/@alignment/g, () => {
        let result = values[0];
        if (values.length === 3) {
          if (i === 0) result = values[0];
          else if (i === length - 1) result = values[2];
          else result = values[1];
        }
        i++;
        return result;
      });
      i = 0;
      replacer = replacer.replace(/@brand-v-alignment/g, () => {
        let result = values[0];
        if (values.length === 3) {
          if (i === 0) result = values[0];
          else if (i === s.template.clips.length - 1) result = values[2];
          else result = values[1];
        }
        i++;
        return result === 'top' ? 'bottom' : 'top';
      });
      i = 0;
      replacer = replacer.replace(/@brand-h-alignment/g, () => {
        let result = values[0];
        if (values.length === 3) {
          if (i === 0) result = values[0];
          else if (i === s.template.clips.length - 1) result = values[2];
          else result = values[1];
        }
        i++;
        return result === 'top' ? 'right' : 'left';
      });
      const vars = replacer.match(/\{\{[A-Za-z\-_ 0-9]+\}\}/g);
      if (isArray(vars)) {
        vars.map((v) => {
          const index = v.substring(2, v.length - 2);
          replacer = replacer.replace(new RegExp(v, 'gi'), s.data[index] || '');
          return v;
        });
      }
      const { clips, font: staticFont } = JSON.parse(replacer);
      const resolution = resolutions.find((r) => r.name === s.data['Video Shape']);
      let audio = s.data.Music;
      if (audio) {
        audio = audio
          .replace(/(,du_[0-9]+(\.[0-9]+)?,)/g, ',')
          .replace(
            /(\/du_[0-9]+(\.[0-9]+)?,)|(,du_[0-9]+(\.[0-9]+)?\/)|(\/du_[0-9]+(\.[0-9]+)?\/)/g,
            '/'
          );
      }
      let UseVoice = s.data.UseVoice;
      let UseBG = s.data.UseBG;
      let UseMusic = s.data.UseMusic;
      let UseOriginalMusic = s.data.UseOriginalMusic;
      let voice = s.data.VoiceOver;
      if (voice) {
        voice = voice
          .replace(/(,du_[0-9]+(\.[0-9]+)?,)/g, ',')
          .replace(
            /(\/du_[0-9]+(\.[0-9]+)?,)|(,du_[0-9]+(\.[0-9]+)?\/)|(\/du_[0-9]+(\.[0-9]+)?\/)/g,
            '/'
          )
          .replace(
            /\/v1637554054\/woxo-video\/audios-v2\//gm,
            '/ac_mp3,br_72k/v1637554054/woxo-video/audios-v2/'
          );
      }
      let totalDuration = 0;
      const textAnimation = s.data.TextAnimation;
      const backgroundGradient = s.data.BackgroundGradient;
      const bgGradScenes = s.data.BgGradScenes;
      const sceneIds = s.data.sceneIds;
      const _resolution = resolution
        ? {
            width: (resolution && resolution.width) || 1080,
            height: (resolution && resolution.height) || 1920
          }
        : {
            width: 1080,
            height: 1920
          };
      const json = {
        id: s.data.id,
        audio,
        voice,
        UseMusic,
        UseBG,
        UseVoice,
        UseOriginalMusic,
        font: s.data.Font ? s.data.Font : staticFont,
        clips: clips
          .map((clip, indexClip) => {
            let dataDurationMode = clip.durationMode;
            let dataDuration = clip.duration;

            /* if (s.data.ScenesLength) {
              dataDurationMode = s.data.ScenesLength[indexClip] === 'smart' ? 'smart' : 'manual';
              dataDuration = s.data.ScenesLength[indexClip];
            }
            */

            const layers = clip.layers
              .filter(
                (e) =>
                  !e.opaque &&
                  ((e.type !== 'woxo-itext' &&
                    e.type !== 'polotno-itext' &&
                    e.path &&
                    e.path.trim() &&
                    e.path !== 'undefined') ||
                    ((e.type === 'woxo-itext' || e.type === 'polotno-itext') &&
                      e.text &&
                      e.text.trim() &&
                      e.text.trim() !== '\n' &&
                      e.text !== 'undefined' &&
                      e.text !== 'undefined\nundefined'))
              )
              .map((e, indexLayer) => {
                let reference =
                  s.template &&
                  s.template.clips[indexClip] &&
                  s.template.clips[indexClip].layers[indexLayer] &&
                  (s.template.clips[indexClip].layers[indexLayer].text ||
                    s.template.clips[indexClip].layers[indexLayer].path);
                reference = reference ? reference.match(/\{\{[A-Za-z_ 0-9]+\}\}/g) : [];
                if (isArray(reference))
                  reference = reference.map((r) => r.substring(2, r.length - 2));
                const result = {
                  ...e,
                  index: indexLayer,
                  id: `${s.data.id}-c_${indexClip}-l_${indexLayer}`,
                  reference
                };

                if (result.type === 'image' || result.type === 'video') {
                  result.zIndex = 2;
                }

                if (result.type === 'woxo-itext' || result.type === 'polotno-itext') {
                  result.zIndex = 5;
                }

                if (result.type === 'woxo-image' || result.type === 'woxo-video') {
                  result.zIndex = 10;
                }

                if (result.type === 'image' && isVideo(result.path)) {
                  result.type = 'video';
                  result.left = 0;
                  result.top = 0;
                  result.path = preview({ url: result.path });
                } else if (
                  result.type === 'video' &&
                  isImage(result.path) &&
                  !isVideo(result.path)
                ) {
                  result.type = 'image';
                }
                if (result.type === 'woxo-image' && isVideo(result.path)) {
                  result.type = 'woxo-video';
                } else if (
                  result.type === 'woxo-video' &&
                  isImage(result.path) &&
                  !isVideo(result.path)
                ) {
                  result.type = 'woxo-image';
                }
                if (result.path) {
                  const newUrl = QueryString.parseUrl(result.path);
                  if (newUrl.query && newUrl.query.preview) {
                    newUrl.query.preview = newUrl.query.preview
                      .replace(/(,du_[0-9]+,)/g, ',')
                      .replace(/(\/du_[0-9]+,)|(,du_[0-9]+\/)|(\/du_[0-9]+\/)/g, '/');
                    result.path = QueryString.stringifyUrl(newUrl);
                  }
                }
                if (
                  (result.type === 'woxo-itext' || result.type === 'polotno-itext') &&
                  indexClip !== 0
                ) {
                  if (result.start) result.start += 0.55;
                  else result.start = 0.55;

                  if (result.stop) result.stop += 0.55;

                  if (textAnimation && textAnimation.toLowerCase() === 'typewriter')
                    result.animatedByLetter = true;
                }
                if (
                  (result.type === 'woxo-itext' || result.type === 'polotno-itext') &&
                  indexClip === 0
                ) {
                  if (result.start && result.start !== 0) {
                    result.animation = true;
                    if (textAnimation && textAnimation.toLowerCase() === 'typewriter')
                      result.animatedByLetter = true;
                  } else {
                    result.animation = false;
                  }
                }

                if (
                  (result.type === 'woxo-itext' || result.type === 'polotno-itext') &&
                  result.x &&
                  result.y &&
                  typeof result.y === 'string'
                )
                  delete result.x;
                return result;
              })
              .filter((e) => {
                return (
                  !e.opaque &&
                  ((e.type !== 'woxo-itext' &&
                    e.type !== 'polotno-itext' &&
                    e.path &&
                    e.path.trim()) ||
                    ((e.type === 'woxo-itext' || e.type === 'polotno-itext') &&
                      e.text &&
                      e.text.trim() &&
                      e.text.trim() !== '\n'))
                );
              })
              .map((e) => {
                const el = {
                  ...e
                };
                if (el.type === 'woxo-itext') {
                  el.width = ((resolution && resolution.width) || 1080) - 90;
                  switch (preset.name) {
                    case 'Solid':
                      el.type = 'woxo-solid';
                      break;
                    case 'Step Up':
                      el.type = 'woxo-stepUp';
                      break;
                    case 'Step Center':
                      el.type = 'woxo-stepCenter';
                      break;
                    case 'Criss Cross':
                      el.type = 'woxo-crissCross';
                      break;
                    case 'Roll Out':
                      el.type = 'woxo-rollOut';
                      break;
                    case 'Under Line':
                      el.type = 'woxo-underLine';
                      break;
                    case 'Center Line':
                      el.type = 'woxo-centerLine';
                      break;
                    case 'Line Up':
                      el.type = 'woxo-lineUp';
                      break;
                    case 'Boundary':
                      el.type = 'woxo-boundary';
                      break;
                    case 'Simple and Clean':
                      el.type = 'woxo-simpleClean';
                      break;
                    case 'Honor':
                      el.type = 'woxo-honor';
                      break;
                    case 'Pop Out':
                      el.type = 'woxo-popOut';
                      break;
                    case 'Trade Mark':
                      el.type = 'woxo-tradeMark';
                      break;
                    case 'Brush Stroke':
                      el.type = 'woxo-brushStroke';
                      break;
                    case 'Dream Meme':
                      el.type = 'woxo-dreamMeme';
                      el.width = (resolution && resolution.width) || 1080;
                      el.height = (resolution && resolution.height) || 1920;
                      break;
                    default:
                      el.type = 'woxo-basic';
                      break;
                  }
                }
                delete el._default;
                return el;
              });

            const duration =
              dataDurationMode === 'manual' && dataDuration
                ? dataDuration
                : sceneAdapterDuration({ ...clip, ..._resolution, layers });

            totalDuration += duration;

            if (
              indexClip !== 0 &&
              layers[1] &&
              layers[1].type === 'woxo-dreamMeme' &&
              layers[2] &&
              layers[2].path &&
              layers[2].path.trim()
            ) {
              const logo = clone(layers[2]);
              logo.start = 0.55;
              layers.push(logo);

              layers[2].stop = 0.55;
            }

            clip.transition.easing = null;
            clip.transition.duration = 0.5;

            if (
              (UseBG === true && backgroundGradient && backgroundGradient !== '') ||
              (bgGradScenes && bgGradScenes.scenes.indexOf(indexClip + 1) !== -1)
            ) {
              const bgGrad = getDataBgGrad(bgGradScenes ? bgGradScenes.type : backgroundGradient);
              const sceneData = clone(layers[0]);

              layers.splice(0, 1);
              layers.unshift({
                type: 'woxo-background',
                colors: bgGrad.colors,
                gradientType: bgGrad.gradientType,
                animation: bgGrad.animation,
                width: resolution.width,
                height: resolution.height,
                index: sceneData.index,
                id: sceneData.id,
                reference: sceneData.reference
              });
            }

            let _id = v4();
            if (clip.id) _id = clip.id;
            else if (sceneIds && sceneIds[indexClip]) _id = sceneIds[indexClip];

            return {
              ...clip,
              index: indexClip,
              id: _id,
              duration,
              layers
            };
          })
          .filter((clip) => {
            return (
              clip.layers.length > 1 ||
              (clip.layers.length === 1 && clip.layers[0].type !== 'woxo-image')
            );
          }),
        resolution: _resolution
      };

      if (json.audio && json.audio.startsWith('https://res.cloudinary.com')) {
        json.audio = json.audio.replace(
          /\/v[0-9]+\/woxo-video\/audios-v2\//gm,
          `/ac_mp3,br_72k,du_${totalDuration + 1}/v1637554054/woxo-video/audios-v2/`
        );
      }
      if (json.voice && json.voice.startsWith('https://res.cloudinary.com')) {
        json.voice = json.voice.replace(
          /\/v[0-9]+\/woxo-video\/audios-v2\//gm,
          `/ac_mp3,br_72k,du_${totalDuration + 1}/v1637554054/woxo-video/audios-v2/`
        );
      }
      return json;
    });

    const _videos = clone(videos);
    if (isFunction(fn)) fn(_videos);
    return _videos;
  } catch (e) {
    console.log(e);
    return [];
  }
};
